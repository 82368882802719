import React from "react";

import Meta from "components/Meta";
import Layout from "components/Layout";
import * as Hero from "components/Hero";
import * as Publications from "components/Publications";
import { PublicationsTemplateProps } from "../types";
import * as ProductCards from "components/ProductCards";
import * as SixtyThreeDegreesBanner from "components/SixtyThreeDegreesBanner";

const Template: React.FC<PublicationsTemplateProps> = ({
  defaultMeta,
  hero_carousel,
  location,
  mediumDegreesBanner,
  meta,
  productCards,
  publicationList,
}) => {
  const slider = productCards.enable_carousel;
  const moreThanOne = productCards?.products.length > 1;
  const productCardsWidget =
    slider && moreThanOne ? <ProductCards.Carousel {...productCards} /> : <ProductCards.Grid {...productCards} />;

  return (
    <Layout>
      <Meta defaultMeta={defaultMeta} location={location} meta={meta} />
      <div>
        {hero_carousel && <Hero.Carousel arrow={true} right_align_button={true} {...hero_carousel} />}
        {publicationList.publications.length > 0 && <Publications.List {...publicationList} />}
        {productCards && productCardsWidget}
        {mediumDegreesBanner && <SixtyThreeDegreesBanner.Medium {...mediumDegreesBanner} />}
      </div>
    </Layout>
  );
};

export default Template;
