import React from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet";

import { PublicationShowcaseProps } from "../types";
import { ButtonStyle } from "components/Button/constants";
import * as Cta from "components/Cta";
import LazyloadImage from "modules/lazyload/Image";
import { useProductSchema } from "modules/meta/schema/ProductSchema";

import * as styles from "./styles.module.scss";

const Showcase: React.FunctionComponent<PublicationShowcaseProps> = ({ cta, publication, title, lazyload }) => {
  const alt = publication[0]?.imageAlt ? publication[0]?.imageAlt : publication[0]?.image.title;
  const className = clsx(styles.publicationShowcase, lazyload && "componentLazyload");
  const name = publication[0]?.preview_title ? publication[0]?.preview_title : publication[0]?.title;
  const url = publication[0]?.cta?.page_link ? publication[0]?.cta?.page_link : publication[0]?.cta?.custom_url;
  return (
    <>
      <Helmet>
        <script key="schema" type="application/ld+json">
          {JSON.stringify(
            useProductSchema({
              description: publication[0]?.preview_description,
              image: publication[0]?.image?.url,
              name,
              price: publication[0]?.price,
              url,
            })
          )}
        </script>
      </Helmet>
      <section className={className}>
        <div className="container extraSmall">
          <div>
            <span>
              <LazyloadImage
                alt={alt}
                desktop={{ height: 380, width: 280, aspectRatio: "14:19" }}
                mobile={{ height: 184, width: 136, aspectRatio: "17:23" }}
                src={publication[0]?.image?.url}
                tagName="img"
              />
            </span>
          </div>
          <div>
            <h3>{title ? title : publication[0]?.title}</h3>
            <Cta.Button {...cta} defaultColour={ButtonStyle.BRAND_BLACK_GRAPE} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Showcase;
