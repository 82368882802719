import { PageReferenceProps } from "modules/unions/usePageReference";

export type useProductSchemaProps = {
  description: string;
  image: string;
  name: string;
  price: string;
  url?: string | PageReferenceProps[];
};

export const useProductSchema = ({ description, image, name, price, url }: useProductSchemaProps) => {
  return {
    "@context": "https://schema.org",
    "@type": ["Product", "Book"],
    name,
    brand: "",
    image,
    sku: "",
    review: "",
    aggregateRating: "",
    description,
    url,
    offers: {
      "@type": "Offer",
      price: price?.replace(/[^0-9.]/gi, ""),
      priceCurrency: "GBP",
      availability: "",
      url,
      priceValidUntil: "",
    },
  };
};
