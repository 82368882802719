import React from "react";
import clsx from "clsx";

import { htmlSafe, stripParagraphTags, hasStartAndEndTag } from "modules/utils";
import { PublicationProps } from "../types";
import { ButtonStyle } from "components/Button/constants";
import * as Cta from "components/Cta";
import LazyloadImage from "modules/lazyload/Image";

import * as styles from "./styles.module.scss";

const Preview: React.FunctionComponent<PublicationProps> = ({
  cta,
  image,
  imageAlt,
  logo,
  preview_description,
  preview_title,
  price,
  title,
}) => {
  const publicationAlt = imageAlt ? imageAlt : image?.title;
  const logoAlt = logo[0]?.alt_text ? logo[0]?.alt_text : logo[0]?.logo?.title;
  const content = htmlSafe(preview_description);
  const safeContent = hasStartAndEndTag(preview_description) ? content : <p>{content}</p>;

  const className = clsx(styles.publicationPreview, styles[`${logo[0]?.colour?.replace(/\s/g, "")}`]);

  return (
    <section className={className}>
      <div>
        <div className={styles.publicationImage}>
          <span>
            <LazyloadImage
              alt={publicationAlt}
              desktop={{ height: 380, width: 285, aspectRatio: "3:4" }}
              mobile={{ height: 176, width: 132, aspectRatio: "3:4" }}
              src={image?.url}
              tagName="img"
            />
          </span>
        </div>
        <div className={styles.logo}>
          <LazyloadImage alt={logoAlt} icon src={logo[0].logo?.url} tagName="img" />
        </div>
      </div>
      <div>
        <div>
          <div>
            <h3>{preview_title ? htmlSafe(stripParagraphTags(preview_title)) : <strong>{title}</strong>}</h3>
            <div>
              <span className={styles.price}>{price}</span>
            </div>
          </div>
        </div>
        <div>{safeContent}</div>
        <Cta.Button {...cta} defaultColour={ButtonStyle.BRAND_BERRY} />
      </div>
    </section>
  );
};

export default Preview;
