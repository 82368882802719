import React from "react";
import { graphql } from "gatsby";
import { DegreesBannerMediumProps } from "components/SixtyThreeDegreesBanner/Medium";
import { PublicationsListProps } from "components/Publications/types";
import { ProductCardsProps } from "components/ProductCards/types";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import * as Publications from "components/Publications";
import { HeroCarouselProps } from "components/Hero/Carousel";
import { DefaultMetaType, MetaType } from "modules/meta/types";
import { NewsletterPopupType } from "modules/newsletter/types";

export type PublicationsQueryTypes = {
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackGlobalMeta: DefaultMetaType;
  contentstackPublicationsListPage: {
    meta: MetaType;
    newsletter: {
      show_popup_page: string;
    };
    hero_carousel: HeroCarouselProps;
    publicationList: PublicationsListProps;
    product_cards: ProductCardsProps;
    medium_degrees_banner: DegreesBannerMediumProps;
  };
};

type PublicationsPageProps = { data: PublicationsQueryTypes; location: any };

const PublicationsPage: React.FC<PublicationsPageProps> = ({ data, location }) => {
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.contentstackPublicationsListPage;

  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  return (
    <Publications.Template
      defaultMeta={data.contentstackGlobalMeta}
      hero_carousel={page?.hero_carousel}
      location={location}
      mediumDegreesBanner={page?.medium_degrees_banner}
      meta={page?.meta}
      productCards={page?.product_cards}
      publicationList={page?.publicationList}
    />
  );
};

export const query = graphql`
  query ($id: String) {
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    contentstackPublicationsListPage(id: { eq: $id }) {
      ...metaPublicationsList
      newsletter {
        show_popup_page
      }
      ...heroCarouselPublications
      ...previewTilesPublications
      ...productCardsPublications
      ...degreesBannerMediumPublications
    }
  }
`;

export default PublicationsPage;
