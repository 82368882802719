import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { PublicationProps } from "components/Publication/types";
import { PublicationsListProps } from "../types";
import * as Publication from "components/Publication";
import LoadMoreButton from "components/LoadMoreButton";
import { useProductSchema } from "modules/meta/schema/ProductSchema";

import * as styles from "./styles.module.scss";

const List: React.FunctionComponent<PublicationsListProps> = ({
  publications,
  number_of_tiles_visible,
  text,
  title,
}) => {
  const displayCount = number_of_tiles_visible ? number_of_tiles_visible : 10;
  const [numberOfItems, setNumberOfItems] = useState(displayCount);

  return (
    <>
      {publications.map((publication: PublicationProps, key: any) => {
        const name = publication.preview_title ? publication.preview_title : publication.title;
        const url = publication?.cta?.page_link ? publication?.cta?.page_link : publication?.cta?.custom_url;
        return (
          <Helmet key={key}>
            <script key="schema" type="application/ld+json">
              {JSON.stringify(
                useProductSchema({
                  description: publication.preview_description,
                  image: publication.image?.url,
                  name,
                  price: publication.price,
                  url,
                })
              )}
            </script>
          </Helmet>
        );
      })}
      <div className={styles.publicationList}>
        <div className="small container">
          {(title || text) && (
            <div>
              {title && <h2>{title}</h2>}
              {text && <p>{text}</p>}
            </div>
          )}
          <ul>
            {publications.slice(0, numberOfItems).map((publication: PublicationProps, key: any) => {
              return (
                <li key={key}>
                  <Publication.Preview {...publication} />
                </li>
              );
            })}
          </ul>
          {publications.length > numberOfItems ? (
            <LoadMoreButton handleClick={() => setNumberOfItems(numberOfItems + displayCount)} label="Show more" />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default List;
